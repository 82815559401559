import {Component, OnInit} from '@angular/core';
import {SwiperOptions, Autoplay} from 'swiper';
import {SwiperComponent} from 'swiper/angular';

// import Swiper core and required modules
import SwiperCore, {Pagination} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  constructor() {
  }

  brands: Array<any> = [
    {
      id: 1,
      name: 'anses'
    },
    {
      id: 2,
      name: 'portsaid'
    },
    {
      id: 3,
      name: 'la-serenisima'
    },
    {
      id: 4,
      name: 'danone'
    },
    {
      id: 5,
      name: 'chr-hansen'
    },
    {
      id: 6,
      name: 'apn'
    },
    {
      id: 7,
      name: 'bartamian'
    }
  ];

  ngOnInit(): void {
  }

  config: SwiperOptions = {
    direction: "horizontal",
    slidesPerView: 4,
    slidesPerGroup: 1,
    spaceBetween: 30,
    allowTouchMove: true,
    mousewheel: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    loop: true,
    observer: true,
    observeParents: true,
    grabCursor: true,
    speed: 600,
    navigation: false,
    pagination: {
      el: '.wrapper-clientes .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        centeredSlides: true
      },
      576: {
        slidesPerView: 'auto',
        centeredSlides: true
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        centeredSlides: false
      },
      991: {
        slidesPerView: 4,
        centeredSlides: false
      }
    }
  };

}
