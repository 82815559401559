import {Component, Input, AfterViewInit, ViewChild, ElementRef, HostListener} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  @Input() nav: any = '';

  @ViewChild('stickyMenu') menuElement: ElementRef;

  sticky: boolean = false;
  elementPosition: any;


  constructor() { }

  redirect(){
    let toggler = document.getElementById('n-toggler');
    setTimeout(function(){
      toggler.click();
    }, 100);
  }

  ngAfterViewInit(): void{
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }
}
