<section class="contacto bg-light border-top border-primary pb-5">
  <div class="container pb-4">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <div class="cont-title text-dark text-center">
          <h2 class="text-uppercase mb-4">Contactanos</h2>
          <h5 class="pb-0 pb-lg-4">Llamanos, estamos a tu disposición las 24hs, los 365 días el año.</h5>
        </div>
      </div>
      <!--<div class="col-11 col-md-9">
        <form class="w-100" *ngIf="!submitted" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-sm-6 form-group" [ngClass]="form.controls['nombre'].invalid && (form.controls['nombre'].dirty || form.controls['nombre'].touched) ? 'field-error' : ''">
              <label for="name">Nombre y Apellido</label>
              <input type="text" class="form-control" id="name" [formControl]="nombre">
              <div class="error py-2" *ngIf="form.controls['nombre'].invalid && (form.controls['nombre'].dirty || form.controls['nombre'].touched)">
                <p class="p-sm text-danger mb-0">Ingrese un nombre válido.</p>
              </div>
            </div>
            <div class="col-sm-6 form-group" [ngClass]="form.controls['empresa'].invalid && (form.controls['empresa'].dirty || form.controls['empresa'].touched) ? 'field-error' : ''">
              <label for="empresa">Empresa</label>
              <input type="text" class="form-control" id="empresa" [formControl]="empresa">
              <div class="error py-2" *ngIf="form.controls['empresa'].invalid && (form.controls['empresa'].dirty || form.controls['empresa'].touched)">
                <p class="p-sm text-danger mb-0">Indique el nombre de su empresa.</p>
              </div>
            </div>
            <div class="col-sm-6 form-group" [ngClass]="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched) ? 'field-error' : ''">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" [formControl]="email">
              <div class="error py-2" *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
                <p class="p-sm text-danger mb-0">Ingrese un email válido.</p>
              </div>
            </div>
            <div class="col-sm-6 form-group" [ngClass]="form.controls['tel'].invalid && (form.controls['tel'].dirty || form.controls['tel'].touched) ? 'field-error' : ''">
              <label for="tel">Tel</label>
              <input type="text" class="form-control" id="tel" [formControl]="tel">
              <div class="error py-2" *ngIf="form.controls['tel'].invalid && (form.controls['tel'].dirty || form.controls['tel'].touched)">
                <p class="p-sm text-danger mb-0">Ingrese un teléfono.</p>
              </div>
            </div>
            <div class="col-12 form-group" [ngClass]="form.controls['mensaje'].invalid && (form.controls['mensaje'].dirty || form.controls['mensaje'].touched) ? 'field-error' : ''">
              <label for="mensaje">Mensaje</label>
              <textarea class="form-control" id="mensaje" rows="5" [formControl]="mensaje"></textarea>
              <div class="error py-2" *ngIf="form.controls['mensaje'].invalid && (form.controls['mensaje'].dirty || form.controls['mensaje'].touched)">
                <p class="p-sm text-danger mb-0">Coloque su mensaje.</p>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-between py-3">
              <small>Todos los campos son obligatorios</small>
              <div>KAPTCHA</div>
            </div>
            <button type="submit" class="btn btn-outline-primary font-OSB text-uppercase rounded-pill px-5 py-3 mx-auto d-table mt-4 mb-0">
              Enviar
            </button>
          </div>
        </form>
        <div *ngIf="submitted" class="w-100 min-h-400 d-flex flex-column justify-content-center align-items-center pb-4">
          <img src="assets/icons/check-solid.svg" alt="check" class="mb-2 max-w-30">
          <h4 class="text-center">Su mensaje se ha enviado correctamente.<br><b>Gracias por contactarnos.</b></h4>
          <button (click)="onReset()" class="btn btn-sm btn-outline-primary font-OSB rounded-pill px-3 py-2 mx-auto d-table mt-4 mb-0">
            Enviar otro mensaje
          </button>
        </div>
      </div>-->
    </div>
  </div>
  <div class="container-fluid bg-light py-5">
    <div class="container px-0 px-md-3">
      <div class="row justify-content-center">
        <div class="col-12 col-md-12 py-4">
          <div class="row align-items-center">
            <div class="col-md-4 pb-5 pb-md-0">
              <ul class="pl-0">
                <li class="mb-5">
                  <img src="assets/icons/pointer.png" class="mx-auto d-table img-fluid max-w-15 mb-3" alt="pointer">
                  <p class="p-sm text-center">Perón 1730 Piso 3 of 50 (1037)<br>
                    C.A.B.A</p>
                </li>
                <li>
                  <img src="assets/icons/whatsapp.png" class="mx-auto d-table img-fluid max-w-20 mb-3" alt="whatsapp">
                  <p class="p-sm text-center mb-1">
                    <a href="https://wa.me/1177109266?text=Consultas" target="_blank" class="text-dark">+54 11 7710-9266</a>
                  </p>
                  <p class="p-sm text-center">
                    <a href="https://wa.me/1143742573?text=Consultas" target="_blank" class="text-dark">+54 11 4374-2573</a>
                  </p>
                </li>
              </ul>
            </div>
            <div class="col-md-8 px-0 px-md-3">
              <div class="cont-iframe">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.895077514985!2d-58.3933339838883!3d-34.60681458045873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac3b149e28b%3A0xf2b26b37590a37aa!2sPiso%203%20of%2050!5e0!3m2!1ses-419!2sar!4v1639878589237!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
