import {Component, Input, AfterViewInit, ViewChild, ElementRef, HostListener} from '@angular/core';

@Component({
  selector: 'app-header-onepage',
  templateUrl: './header-onepage.component.html',
  styleUrls: ['./header-onepage.component.scss']
})
export class HeaderOnepageComponent implements AfterViewInit {

  @Input() nav: any = '';

  @ViewChild('stickyMenu') menuElement: ElementRef;

  sticky: boolean = false;
  elementPosition: any;
  public nav_main = ['home', 'servicios', 'clientes', 'demos', 'trabajos', 'contacto'];
  public nav_mapa = ['home', 'demos', 'nosotros', 'clientes', 'servicios',  'trabajos', 'novedades', 'contacto'];

  constructor() { }

  ngAfterViewInit(): void{
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  redirect(){
    let toggler = document.getElementById('n-toggler');
    setTimeout(function(){
      toggler.click();
    }, 100);
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(){
    const windowScroll = window.pageYOffset;
    const header = document.querySelector('header');
    if(header){
      if(windowScroll > this.elementPosition){
        this.sticky = true;
        header.classList.add('sticky');
      } else {
        this.sticky = false;
        header.classList.remove('sticky');
      }
    }
  }
}
