<app-header [nav]="nav_main"></app-header>
<section class="wrapper-content border-top border-primary-op pt-0">
  <div class="col-md-12 bg-light py-3">
    <a class="ico-back-circle mr-auto w-100" routerLink=""><img src="/assets/icons/back-circle.png" class="img-fluid max-w-30" alt="back"></a>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-7 pt-3" *ngFor="let ne of this.notice">
        <div class="d-flex align-items-center pb-3">
          <img src="/assets/icons/calendar.png" class="max-w-10 mr-2" alt="calendar">
          <p class="mb-0">{{ne.fecha}}</p>
        </div>
        <hr>
        <h2 class="font-OB text-primary">{{ne.title}}</h2>
        <h4 class="mb-5">{{ne.subtitle}}</h4>
        <img src="/assets/news/{{ne.img}}.jpg" class="w-100 img-fluidz vebo" alt="{{ne.img}}">
        <hr>
        <p [innerHTML]="ne.description">{{ne.description}}</p>
      </div>
    </div>
  </div>
</section>
<app-footer [nav]="nav_mapa"></app-footer>
