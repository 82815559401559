<section class="featured border-top border-primary-op py-0">
  <div class="container-fluid overflow-hidden px-0">
    <div class="row no-gutters justify-content-center">
      <div class="col-12 col-md order-2 order-md-1">
        <div class="cont-img"></div>
      </div>
      <div class="col-12 col-md order-1 order-md-2 content-info d-flex justify-content-center justify-content-md-start align-items-center pl-4 pr-4 pr-md-0 py-5">
        <h3 class="font-OL text-center text-md-left pl-0 pl-md-5 mb-0">Construimos. Asistimos. Auditamos.</h3>
      </div>
    </div>
  </div>
</section>
