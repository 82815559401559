import {Component, ViewEncapsulation, ViewChild, OnInit, AfterViewInit, Injectable, Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-interna-novedades',
  templateUrl: './interna-novedades.component.html',
  styleUrls: ['./interna-novedades.component.scss'],
})

@Injectable({
  providedIn: 'root',
})
export class InternaNovedadesComponent implements OnInit {
  public notice: Array<any> = [];

  constructor(private router: Router) {
  }

  public nav_main = ['home', 'servicios', 'clientes', 'demos', 'trabajos', 'contacto'];
  public nav_mapa = ['home', 'demos', 'nosotros', 'clientes', 'servicios', 'trabajos', 'novedades', 'contacto'];
  public novedades = [
    {
      id: 1,
      slug: 'progress-software',
      title: 'Progress Software',
      subtitle: 'Hace poco tiempo hemos firmado contrato con la firma Progress (USA) para constituirnos en “Service Provider Partner”.',
      fecha: '12/08/2021',
      short_description: 'Hace poco tiempo hemos firmado contrato con la firma Progress (USA) para constituirnos...',
      description: 'Esta licencia nos permite distribuir sus productos, ser vehículo de entrenamiento en las implementaciones y brindar servicios de consultoría especializada a los clientes y usuarios que opten por desarrollar su estructura informática utilizando esta plataforma de vanguardia mundial como soporte de sus negocios y/o actividades.\n\nContinuamos consolidando así nuestra propuesta de servicios y reafirmamos nuestro camino de crecimiento en consonancia con el avance tecnológico de la actividad.',
      img: 1
    },
    {
      id: 2,
      slug: 'iso-9001',
      title: 'ISO 9001',
      subtitle: '2020 y 2021 han sido años de reflexión, probablemente para muchas personas y para muchas organizaciones.',
      fecha: '26/12/2021',
      short_description: '2020 y 2021 han sido años de reflexión, probablemente para muchas personas y para muchas...',
      description: 'Años en que muchas personas, empresas y organizaciones debieron decidir acciones diversas, algunas trágicas, otras de mera subsistencia y algunas, entre las que nos contamos, optaron por, aprovechando la experiencia y los profundos intercambios de ideas que tuvieron lugar en los tiempos en que la pandemia y sus consecuencias obligaron a crear, sentar las bases de un futuro crecimiento sostenido para el que una adecuada y sensata gestión del cambio resulta necesaria.\n\n \n\nEn ese marco, hemos iniciado una profunda transformación de nuestra estructura y procedimientos que permita próximamente certificar al Estudio bajo normas ISO 9000. Sirva esto para expresar nuestra confianza en el futuro mediato e inmediato y garantizar a nuestros clientes actuales y potenciales que nuestra vocación de ser los mejores interlocutores informáticos y organizacionales para acompañarlos en su crecimiento y/o contribuir desde la experiencia y conocimientos acumulados en todos nuestros colaboradores a dar solución a cualquier situación que se encuentre dentro de su alcance y, si así no fuera, comprometernos a encontrarla en la vasta red de Estudios colegas que integramos.',
      img: 2
    },
    {
      id: 3,
      slug: 'capacitacion',
      title: 'Capacitacion',
      subtitle:  'Hemos iniciado un Plan de Entrenamiento y actualización tecnológica para nuestros colaboradores, en conjunto con la firma Educación IT.',
      fecha:  '05/02/2022',
      short_description: 'Hemos iniciado un Plan de Entrenamiento y actualización tecnológica para nuestros...',
      description: 'Con la idea de mantener un adecuado dominio de los avances tecnológicos en la actividad y pensando en lograr la mejor manera de satisfacer los requerimientos de nuestros clientes actuales, ampliando a la vez la oferta de servicios para los potenciales.',
      img: 3
    },
    {
      id: 4,
      slug: 'designacion-por-apn',
      title: 'Designación por APN',
      subtitle:  'La Administración de Parques Nacionales nos ha designado para migrar su plataforma de tecnológica de version OPENEDGE 10.1C a OPENEDGE 12.2.',
      fecha:  '12/01/2022',
      short_description: 'La Administración de Parques Nacionales nos ha designado para migrar su plataforma de...',
      description: 'Asi, la APN dispondrá de la mas moderna plataforma tecnológica de desarrollo de aplicaciones. Esta actualización es critica pues la plataforma actual esta perdiendo vigencia de certificación con la version de Windows implementada en ese organismo.',
      img: 4
    }
  ];

  ngOnInit(): void {
    let final_url = this.router.url.split('/')[2];

    this.notice = this.novedades.filter(c =>{
      if(c.slug === final_url){
        return c;
      }else{
        return '';
      }
    });
  }
}
