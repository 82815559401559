<header #stickyMenu>
  <nav class="navbar navbar-expand-lg navbar-light bg-white py-4">
    <div class="container justify-content-between">
    <a class="navbar-brand px-2 px-md-0" href="#"><img src="assets/rcianciarelli.png" alt="RCianciarelli & Asoc."></a>
    <div>
      <button class="navbar-toggler border-0 px-2" type="button" id="n-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav d-flex flex-column flex-lg-row justify-content-center align-items-center justify-content-lg-start align-items-lg-start">
          <li class="li-sm font-OM nav-item text-center text-lg-left px-1 mb-2 mb-lg-0" *ngFor="let n of nav">
            <a [routerLink]="['/', n ]" class="nav-link text-uppercase">{{n}}</a>
          </li>
        </ul>
      </div>
    </div>
    </div>
  </nav>
</header>
