import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {

  constructor() {}

  proyects: Array<any> = [
    {
      title: 'Sistema de control y administración de la compra y traslado de leche.',
      clients: 'Mastellone Hnos. SA',
      finalized: true
    },
    {
      title: 'Sistema de liquidación de productores lácteos para la industria lechera',
      clients: 'Mastellone Hnos. SA',
      finalized: false
    },
    {
      title: 'Automatización de procesos en laboratorios',
      clients: 'Mastellone Hnos. SA',
      finalized: true
    },
    {
      title: 'Reingeniería Administrativa y de Gestión de los sistemas de la Administración de Parques Nacionales',
      clients: '',
      finalized: true
    },
    {
      title: 'Supervisión de Implantación de SAP en ANSES',
      clients: '',
      finalized: true
    },
    {
      title: 'Bases de datos',
      clients: '',
      finalized: true
    },
    {
      title: 'Desarrollo del sistema ReNaRI para la Administracion de Parques Nacionales',
      clients: '',
      finalized: true
    },
    {
      title: 'Sistema de Remuneraciones Variables',
      clients: 'Grupo Danone Argentina',
      finalized: true
    },
    {
      title: 'Sistema de Tesoreria',
      clients: 'Grupo Danone Argentina',
      finalized: true
    },
    {
      title: 'Auditorias operativas',
      clients: 'Mastellone Hnos. SA ARGENTORES - Port Said - Navarro Gomas S.A. - Bingo Lujan - Chr. Hansen de Argentina S.A.',
      finalized: true
    },
    {
      title: 'Auditoria en concursos preventivos',
      clients: 'Correo Argentino - Comi Salud',
      finalized: true
    },
    {
      title: 'Sistemas de soporte para certificación de normas de calidad ISO',
      clients: '',
      finalized: true
    },
    {
      title: 'Auditoria para certificación de normas de calidad',
      clients: '',
      finalized: true
    },
    {
      title: 'Sistema de captura de datos en equipos móviles',
      clients: 'Mastellone Hnos. SA',
      finalized: true
    },
    {
      title: 'Sistema integrado de comercio exterior',
      clients: 'Mastellone Hnos. SA',
      finalized: true
    },
    {
      title: 'Gestión comercial para la industria gráfica ',
      clients: 'Intergrafica SA',
      finalized: true
    },
    {
      title: 'Sistema integral para editoriales',
      clients: 'Edit Prof SRL',
      finalized: true
    },
    {
      title: 'Sistema de liquidación de fletes para empresas de distribución masiva',
      clients: 'Grupo Danone Argentina',
      finalized: true
    },
  ];
  
  ngOnInit(): void {
  }

}
