<section class="servicios">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <div class="cont-title text-dark text-center pb-4 pb-md-5">
          <h2 class="text-uppercase mb-4">Servicios que brindamos</h2>
          <h5 class="pb-4">Pellentesque pellentesque ante semper lacus gravida ullamcorper. Cras sit amet erat quam. Proin vitae porta dui.</h5>
        </div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
      <div class="col" *ngFor="let s of servicios">
        <div class="card h-100 border-0 mt-4">
          <img src="/assets/icons/{{s.name}}.png" class="img-fluid max-w-30 mx-auto" alt="{{s.title}}">
          <div class="card-body text-center">
            <h5 class="card-title">{{s.title}}</h5>
            <p class="card-text">{{s.descripcion}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
