import {Component, ViewEncapsulation, ViewChild, OnInit, Input} from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


// import Swiper core and required modules
import SwiperCore, { Pagination, SwiperOptions } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-intro-news',
  templateUrl: './intro-news.component.html',
  styleUrls: ['./intro-news.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IntroNewsComponent implements OnInit{
  closeResult = '';
  public news: any = [];

  @Input() fullNews: any = '';

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  config: SwiperOptions = {
    direction: "horizontal",
    slidesPerView: 4,
    spaceBetween: 30,
    allowTouchMove: true,
    mousewheel: true,
    observer: true,
    observeParents: true,
    grabCursor: true,
    speed: 600,
    navigation: {
      nextEl: '.wrapper-news .swiper-button-next',
      prevEl: '.wrapper-news .swiper-button-prev',
    },
    pagination: {
      el: '.wrapper-news .swiper-pagination',
      dynamicBullets: false,
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        centeredSlides: true
      },
      576: {
        slidesPerView: 'auto',
        centeredSlides: true
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        centeredSlides: false
      },
      991: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        centeredSlides: false
      }
    },
    on: {
      init: function () {
        console.log('entro');
        /*let swiper1= document.querySelector('.mySwiper');
        if(swiper1.slides.length < 5){
          console.log('entro');
        }*/
      },
    },
  };

  //Modal
  open(content:any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'Nosotros',
      size: 'lg',
      centered: true
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
