<section class="partners border-top border-primary">
  <div class="container overflow-hidden px-0">
    <div class="row justify-content-center">
      <div class="col-md-10 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <h4 class="title-line-left text-uppercase pl-5 pl-sm-0 pr-5 my-3">Partners</h4>
        <div class="content-brands d-flex justify-content-center align-items-center px-3 px-sm-0">
          <a href="" target="_blank" class="p-3"><img src="assets/brands/microsoft.png" class="img-fluid max-w-120" alt="Microsoft"></a>
          <a href="" target="_blank" class="p-2 p-sm-3"><img src="assets/brands/progress.svg" class="img-fluid img-svg" alt="Progress"></a>
        </div>
      </div>
    </div>
  </div>
</section>
