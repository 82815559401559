import { Component, OnInit } from '@angular/core';
import {ModalDismissReasons, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-conocenos',
  templateUrl: './modal-conocenos.component.html',
  styleUrls: ['./modal-conocenos.component.scss']
})
export class ModalConocenosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
