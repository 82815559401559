<section class="intro bg-light pt-0">
  <div class="wrapper-destacado position-relative">
    <div class="container h-100 d-flex justify-content-center align-items-center">
      <div class="cont-info d-flex flex-column justify-content-center align-items-center text-white">
        <img src="assets/cucarda.png" class="max-w-120" alt="Más de 30 años de experiencia">
        <h1 class="text-center mb-3">Consulting and<br>Technology</h1>
        <h4 class="mb-4">Más de 30 años de experiencia</h4>
        <a (click)="open(content)" class="btn btn-primary text-uppercase rounded-pill py-3 px-5">conocenos</a>
      </div>
    </div>
  </div>
  <div class="wrapper-news" id="nav-news">
    <div class="container overflow-hidden">
      <div class="row justify-content-center">
        <div class="col-10 col-sm-9 col-md-12">
          <swiper [config]="config" class="mySwiper">
            <ng-template swiperSlide *ngFor="let ne of fullNews" class="bg-transparent mb-4">
              <div routerLink="/novedades/{{ne.slug}}" class="card cursor-pointer h-100 bg-transparent border-0 mt-4">
                <div class="cont-img bg-lightGray d-flex justify-content-center align-items-center">
                  <img src="assets/news/{{ne.img}}.jpg" class="img-fluid rounded-0" alt="{{ne.img}}">
                </div>
                <div class="card-body text-center px-3">
                  <h4 class="card-title font-OSB">{{ne.title}}</h4>
                  <p class="card-text">{{ne.short_description}}</p>
                  <div class="btn btn-sm btn-outline-primary font-OSB rounded-pill px-4 mb-0">Ver más</div>
                </div>
              </div>
            </ng-template>
          </swiper>
          <div class="wrapper-controls position-relative d-table mx-auto px-5 py-4">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-pagination custom-primary"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-modal>
  <div class="modal-header bg-primary py-4 px-lg-5 d-flex justify-content-center">
    <h5 class="modal-title font-OSB text-white text-uppercase px-5 text-center text-md-left mb-0" id="modal-basic-title">Estudio de Sistemas Rodolfo Cianciarelli & Asoc.</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span class="ico-close-circle">
        <img src="assets/icons/close-circle.png" alt="close">
      </span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column align-items-center py-4 px-lg-5">
    <h5 class="h5-sm font-OSB text-center pt-3">Somos un estudio profesional, <br class="d-none d-lg-block">con foco en los servicios informáticos. </h5>
    <img src="assets/rcianciarelli-30.png" class="w-100 max-w-240 my-5" alt="Cianciarelli & Asoc. | 30 años">
    <p><b>Construimos soluciones, asistimos profesionalmente y auditamos servicios de sistemas. </b><br class="d-none d-sm-block">Nuestros clientes
      son grandes y medianas empresas y organismos públicos.
      Desde hace 30 años estamos presentes en las soluciones que nuestros clientes requieran.</p>
  </div>
  <div class="modal-footer bg-light border-top-0 justify-content-center py-4 px-lg-5">
    <button class="btn btn-outline-primary font-OSB text-uppercase rounded-pill px-5 py-3 m-0">Novedades</button>
  </div>
</ng-template>
