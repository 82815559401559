<footer id="footer" class="border-top border-primary-op">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-md-4 d-flex justify-content-center justify-content-md-start pr-xl-5">
        <a href=""><img src="assets/rcianciarelli-30.png" class="img-fluid max-w-220" alt="Cianciarelli"></a>
      </div>
      <div class="col-sm-5 col-md-8 pt-5 pt-md-0">
        <div class="row justify-content-center justify-content-md-start">
          <div *ngFor="let n of nav"
               class="col-sm-6 col-md-3 d-flex flex-column justify-content-center justify-content-md-start">
            <p class="small text-center text-md-left">
              <a *ngIf="n !== 'novedades' && n !== 'nosotros'" href="#nav-{{n}}" pageScroll class="text-dark text-uppercase">{{n}}</a>
              <a *ngIf="n === 'novedades'" href="#nav-home" pageScroll class="text-dark text-uppercase">{{n}}</a>
              <a *ngIf="n === 'nosotros'"  (click)="open(content2)" class="text-dark text-uppercase">{{n}}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid border-top border-gray py-4 mt-n3 mt-lg-0">
    <div class="row">
      <div class="col">
        <p class="small text-center mb-0">© 2021. Todos los derechos reservados.</p>
      </div>
    </div>
  </div>
</footer>

<ng-template #content2 let-modal>
  <div class="modal-header bg-primary py-4 px-lg-5 d-flex justify-content-center">
    <h5 class="modal-title font-OSB text-white text-uppercase px-5 text-center text-md-left mb-0" id="modal-basic-title">Estudio de Sistemas Rodolfo Cianciarelli & Asoc.</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span class="ico-close-circle">
        <img src="assets/icons/close-circle.png" alt="close">
      </span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column align-items-center py-4 px-lg-5">
    <h5 class="h5-sm font-OSB text-center pt-3">Somos un estudio profesional, <br class="d-none d-lg-block">con foco en los servicios informáticos. </h5>
    <img src="assets/rcianciarelli-30.png" class="w-100 max-w-240 my-5" alt="Cianciarelli & Asoc. | 30 años">
    <p><b>Construimos soluciones, asistimos profesionalmente y auditamos servicios de sistemas. </b><br class="d-none d-sm-block">Nuestros clientes
      son grandes y medianas empresas y organismos públicos.
      Desde hace 30 años estamos presentes en las soluciones que nuestros clientes requieran.</p>
  </div>
  <div class="modal-footer bg-light border-top-0 justify-content-center py-4 px-lg-5">
    <button class="btn btn-outline-primary font-OSB text-uppercase rounded-pill px-5 py-3 m-0">Novedades</button>
  </div>
</ng-template>
