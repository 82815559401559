import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwiperModule } from 'swiper/angular';
import { IntroNewsComponent } from './shared/intro-news/intro-news.component';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ClientesComponent } from './shared/clientes/clientes.component';
import { DemosComponent } from './shared/demos/demos.component';
import { ProyectosComponent } from './shared/proyectos/proyectos.component';
import { FeaturedComponent } from './shared/featured/featured.component';
import { PartnersComponent } from './shared/partners/partners.component';
import { ContactoComponent } from './shared/contacto/contacto.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ServiciosComponent } from './shared/servicios/servicios.component';
import { HeaderComponent } from './shared/header/header.component';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import { ModalConocenosComponent } from './shared/modal-conocenos/modal-conocenos.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InternaNovedadesComponent } from './shared/interna-novedades/interna-novedades.component';
import { HomeComponent } from './shared/home/home.component';
import { HeaderOnepageComponent } from './shared/header-onepage/header-onepage.component';
import {FooterOnepageComponent} from './shared/footer-onepage/footer-onepage.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    IntroNewsComponent,
    ClientesComponent,
    DemosComponent,
    ProyectosComponent,
    FeaturedComponent,
    PartnersComponent,
    ContactoComponent,
    FooterComponent,
    FooterOnepageComponent,
    ServiciosComponent,
    HeaderComponent,
    ModalConocenosComponent,
    InternaNovedadesComponent,
    HomeComponent,
    HeaderOnepageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    SwiperModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule
  ],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
