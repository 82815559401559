import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators, FormArray, NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';


export class Hero {
  constructor() {  }
}

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})



export class ContactoComponent implements OnInit {

  form: FormGroup;
  nombre: FormControl = new FormControl("", [Validators.required]);
  empresa: FormControl = new FormControl("", [Validators.required]);
  email: FormControl = new FormControl("", [Validators.required]);
  tel: FormControl = new FormControl("", [Validators.required]);
  mensaje: FormControl = new FormControl("", [Validators.required]);
  submitted: boolean = false;
  responseMessage: string;
  honeypot: FormControl = new FormControl(""); // we will use this to prevent spam

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.form = this.formBuilder.group({
      nombre: this.nombre,
      empresa: this.empresa,
      tel: this.tel,
      email: this.email,
      mensaje: this.mensaje,
      honeypot: this.honeypot
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.status === "VALID") {
      console.log('entro');
      this.submitted = true;
    }
  }

  onReset(){
    this.submitted = false;
    this.form.reset();
  }

}
