import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {

  constructor() { }

  servicios: Array<any> = [
    {
      title: 'Reingeniería de procesos',
      name: 'foco',
      descripcion: 'Evaluamos los procesos de gestión para acercar la mejor propuesta, que le brinde seguridad y eficacia. Estamos siempre enfocados en los objetivos y necesidades del cliente.',
    },
    {
      title: 'Desarrollo informático',
      name: 'sitemap-solid',
      descripcion: 'Construimos sistemas grandes, medianos y chicos, desde el diseño conceptual hasta la implementación e implantación. Pueden estar integrados a otros o ser autónomos.',
    },
    {
      title: 'Auditoría de Sistemas',
      name: 'tasks-solid',
      descripcion: 'Nuestro servicio de auditoría de sistemas tiene por objetivo detectar los riesgos que la función informática pueda presentar para el patrimonio o eficacia del plan de negocios de una empresa.',
    },
    {
      title: 'Soluciones informáticas',
      name: 'forward',
      descripcion: '¿Necesita una solución que está disponible en el mercado y no sabe por cual inclinarse? Trabajamos para construir criterios sólidos para que pueda elegir siempre lo mejor.',
    },
    {
      title: 'Internet de las cosas',
      name: 'world-circle',
      descripcion: 'Sabemos que hay “cosas” que funcionan en la red. Brindamos soluciones de automatización y comunicación de procesos industriales, de seguridad, logística y comercial.',
    },
    {
      title: 'Integración SAP',
      name: 'integrate',
      descripcion: 'Acompañamos al cliente en todo el proceso. Desde la selección de las opciones técnicas y funcionales, la consultora que llevará a cabo la implantación, hasta la puesta en marcha.',
    },
    {
      title: 'Pericias Informáticas',
      name: 'people',
      descripcion: 'Con el avance de la informática, son cada vez más personas en los litigios se presentan en tribunales. Damos asesoramiento y apoyatura para este conjunto de disciplinas',
    }
  ];

  ngOnInit(): void {
  }

}
