<section class="clientes border-top border-primary-op">
  <div class="wrapper-clientes position-relative overflow-hidden pb-5">
    <div class="container">
      <swiper [config]="config" class="mySwiper">
        <ng-template swiperSlide *ngFor="let brand of brands" class="bg-transparent mb-4">
          <div class="cont-img bg-lightGray">
            <img src="assets/brands/{{brand.name}}.jpg" alt="{{brand.name}}">
          </div>
        </ng-template>
      </swiper>
      <div class="swiper-pagination custom-primary"></div>
    </div>
  </div>
</section>
