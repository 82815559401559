<section class="demos bg-lightPrimary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <div class="cont-title text-dark text-center pb-4 pb-md-5">
          <h2 class="text-uppercase mb-4">Demos de softwares</h2>
          <h5 class="pb-0 pb-lg-4">Pellentesque pellentesque ante semper lacus gravida ullamcorper. Cras sit amet erat quam. Proin vitae porta dui.</h5>
        </div>
      </div>
    </div>
    <div class="row row-cols-lg-2 g-0 justify-content-center overflow-hidden">
      <div class="col-10 col-lg d-flex align-items-center content-info">
        <div class="row justify-content-center justify-content-lg-end ml-xl-5">
          <div class="col-md-9 d-flex flex-column justify-content-center align-items-center pl-xl-5 py-4">
            <h3 class="text-uppercase text-center mb-4">Sistemas APN</h3>
            <h5 class="text-center pb-4">Pellentesque pellentesque ante semper lacus gravida ullamcorper. Cras sit amet erat quam. Proin vitae.</h5>
            <a href="http://186.189.228.157/scripts/cgiip.exe/WService=wsApn/index.r" target="_blank" class="btn btn-sm btn-primary font-OSB rounded-pill px-4 mb-0">Ver demo</a>
          </div>
        </div>
      </div>
      <div class="col-lg px-0 px-lg-3">
        <img src="assets/demos/sistemas-apn.jpg" alt="Sistemas APN" class="img-fluid">
      </div>
    </div>
  </div>
</section>
