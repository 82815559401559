<app-header-onepage [nav]="nav_main"></app-header-onepage>
<app-intro-news id="nav-home" [fullNews]="novedades"></app-intro-news>
<app-servicios id="nav-servicios"></app-servicios>
<app-clientes id="nav-clientes"></app-clientes>
<app-demos id="nav-demos"></app-demos>
<app-featured></app-featured>
<app-proyectos id="nav-trabajos"></app-proyectos>
<app-partners id="nav-partners"></app-partners>
<app-contacto id="nav-contacto"></app-contacto>
<app-footer-onepage [nav]="nav_mapa"></app-footer-onepage>

