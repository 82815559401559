import {Component, HostListener, Output, Input, ViewChild} from '@angular/core';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/core';
import {RouterOutlet} from '@angular/router';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Cianciarelli';

  constructor() {}

  ngOnInit(): void {

      //btnToTop
      $(window).on('scroll resize', function() {
        let windowTop, windowBottom, heightfoo, windowBottomZone, stoptop, fh, stopbottom, btnIni;

        //dimensiones footer & pag
        windowTop = $(document).scrollTop();
        heightfoo = $('footer').height();
        windowBottom = windowTop + window.innerHeight;
        windowBottomZone = windowBottom + 50;
        stoptop = $('footer').offset().top;
        fh = $('footer').height();
        stopbottom = stoptop + fh;

        //Btn to top
          btnIni = $('.ico-top');
          btnIni.addClass('visible');

          if(stopbottom < windowBottomZone){
            btnIni.css('margin-bottom',  heightfoo - 40);
            btnIni.addClass('bottom');
          }else{
            btnIni.css('margin-bottom',  0);
            btnIni.removeClass('bottom');
          }

          if(windowTop === 0){
            btnIni.removeClass('visible');
          }
      });
  }
}
