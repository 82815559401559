
<ng-template>
  <div class="modal-header bg-primary py-4 px-lg-5 d-flex justify-content-center">
    <h5 class="modal-title font-OSB text-white text-uppercase px-5 text-center text-md-left mb-0" id="modal-basic-title">Estudio de Sistemas Rodolfo Cianciarelli & Asoc.</h5>
    <button type="button" class="close" aria-label="Close">
      <span class="ico-close-circle">
        <img src="assets/icons/close-circle.png" alt="close">
      </span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column align-items-center py-4 px-lg-5">
    <h5 class="h5-sm font-OSB text-center pt-3">Somos un estudio profesional, <br class="d-none d-lg-block">con foco en los servicios informáticos. </h5>
    <img src="assets/rcianciarelli-30.png" class="w-100 max-w-240 my-5" alt="Cianciarelli & Asoc. | 30 años">
    <p><b>Construimos soluciones, asistimos profesionalmente y auditamos servicios de sistemas. </b><br class="d-none d-sm-block">Nuestros clientes
      son grandes y medianas empresas y organismos públicos.
      Desde hace 30 años estamos presentes en las soluciones que nuestros clientes requieran.</p>
  </div>
  <div class="modal-footer bg-light border-top-0 justify-content-center py-4 px-lg-5">
    <button class="btn btn-outline-primary font-OSB text-uppercase rounded-pill px-5 py-3 m-0">Novedades</button>
  </div>
</ng-template>

