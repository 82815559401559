<section class="proyectos bg-primary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <div class="cont-title text-white text-center pb-4 pb-md-5">
          <h2 class="text-uppercase mb-4">Proyectos</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <perfect-scrollbar class="col-10 wrapper-proyects">
        <ul class="list-group list-group-flush pl-0">
          <li *ngFor="let p of proyects" class="list-group-item text-light border-light-opacity bg-transparent px-0 py-4">
            <div class="row no-gutters justify-content-between align-items-center">
              <div class="content-info pr-md-5">
                <h5 class="mb-0">{{p.title}}</h5>
                <div *ngIf="p.clients !== ''" class="btn small btn-secondary btn-clients cursor-text rounded-pill text-uppercase px-3 py-2 mt-2 mb-0">{{p.clients}}</div>
              </div>
              <div *ngIf="p.finalized !== true" class="btn btn-text btn-status border border-light text-white btn-sm rounded-pill py-2 px-4 mb-0 ml-0 ml-md-auto ml-md-0 mt-3 mt-md-0">En curso</div>
            </div>
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
  </div>
</section>
