<!--sections-->
<router-outlet></router-outlet>
<app-modal-conocenos></app-modal-conocenos>

<!--Btn to top-->
<div class="ico-top position-fixed" id="btnTotop">
  <a href="#nav-home" pageScroll class="text-primary rounded-circle bg-white border border-primary d-flex align-items-center justify-content-center">
    <img src="assets/icons/chevron-up-solid.png" alt="arrow">
  </a>
</div>

