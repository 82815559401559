import { Component, OnInit, Input } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer-onepage',
  templateUrl: './footer-onepage.component.html',
  styleUrls: ['./footer-onepage.component.scss']
})
export class FooterOnepageComponent implements OnInit {
  @Input() nav: any;
  closeResult = '';

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  redirect(name: any){}

  //Modal
  open(content2:any) {
    this.modalService.open(content2, {
      ariaLabelledBy: 'Nosotros',
      size: 'lg',
      centered: true
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
